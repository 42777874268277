const profile = {
	name: 'Prashant Singh',
	position: 'Game Devleoper',
	email: 'prashant19194@gmail.com',
	githubLink: 'https://github.com/',
	github: 'prashant-singh',
	linkedinLink: 'https://www.linkedin.com/in/',
	linkedin: 'kshatriyaprashant',
	profilePic: 'profilepic.png'
};
export default profile;