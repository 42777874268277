const workList = [
	{
		name: "Word Search-Crossword Wonders",
		type: "Game",
		tags: [
			"Unity3D", "C#", "Firebase"
		],
		links: [
			{
				Name: "Android",
				url: "https://play.google.com/store/apps/details?id=com.egl.android.wordtravel"
			},
			{
				Name: "IOS",
				url: "https://apps.apple.com/us/app/word-search-crossword-wonders/id1671991075"
			}
		],
		img: "projects/wordsearch-crosswordpuzzle.png",
		detail: "Crossword puzzle game with powerups and multiple language support.",
		event_name: "word_search",
		highlight: true,
	},
	{
		name: "Disney - BYJU'S Early Learn",
		type: "Game",
		tags: [
			"Unity3D", "C#", "QR Scanner"
		],
		links: [
			{
				Name: "Android",
				url: "https://play.google.com/store/apps/details?id=com.byjus.k3"
			},
			{
				Name: "IOS",
				url: "https://apps.apple.com/in/app/disney-byjus-early-learn/id1470583693"
			}
		],
		img: "projects/byjus_early_learn_thumb.png",
		detail: "Worked on the new Home Screen feature and curated list logic.",
		event_name: "byjus_app",
		highlight: true,
	},
	{
		name: "Juice Team [Math Wizard] for Osmo",
		type: "Game",
		tags: [
			"Unity3D", "C#", "iPad"
		],
		links: [
			{
				Name: "Website",
				url: "https://www.playosmo.com/en/shopping/games/math-wizard-magical-workshop/",
			}
		],
		img: "projects/juice-team-thumb.jpg",
		detail: "An addition, subtraction and multiplication adventure Soccer, Baseball and Relay Race.",
		event_name: "math_wizard",
		highlight: true,
	},
	{
		name: "Midnight Noise (Realtime Maps with GPS Tracking)",
		type: "Game",
		tags: [
			"Unity3D", "C#", "OpenMapsSDK", "IOS", "Android"
		],
		links: [
			{
				Name: "Website",
				url: "https://midnightnoise.com/",
			},
			{
				Name: "IOS",
				url: "https://apps.apple.com/ca/app/midnight-noise/id1490544831"
			},
			{
				Name: "Android",
				url: "https://play.google.com/store/apps/details?id=com.midnightnoise"
			}
		],
		img: "/projects/midnight_noise_thumb.png",
		detail: "Realtime GPS tracking on Maps where you can connect with other players and attend live events on a pinned location.",
		event_name: "midnight_noise",
		highlight: true,
	},
	{
		name: "THIS",
		type: "Game",
		tags: [
			"Unity3D", "C#"
		],
		links: [
			{
				Name: "Website",
				url: "https://madperception.github.io/",
			},
			{
				Name: "Android",
				url: "https://play.google.com/store/apps/details?id=com.madperception.thisgame"
			},
			{
				Name: "Windows",
				url: "https://www.microsoft.com/en-in/p/this/9nblggh5kj2x"
			}
		],
		img: "projects/this_thumb.jpg",
		detail: "An addition, subtraction and multiplication adventure Soccer, Baseball and Relay Race.",
		event_name: "this_game",
		highlight: true,
	},
	{
		name: "Lidl Aquamini",
		type: "Game",
		tags: [
			"Unity3D", "C#", "QR Scanner"
		],
		links: [
			{
				Name: "Android",
				url: "https://play.google.com/store/apps/details?id=com.lidl.aquamini",
			}
		],
		img: "projects/aquamini_thumb.png",
		detail: "Lidl AquaMini A fun way to teach children about the cleanliness of our Oceans so the Fishes can live freely.",
		event_name: "liddle",
		highlight: true,
	},
	{
		name: "BBBlackjack",
		type: "Game",
		tags: [
			"Unity3D", "C#"
		],
		links: [
		],
		img: "projects/bbblackjack_thumb.png",
		detail: "A Blackjack game where you can create clubs with your friends and create tables with your chosen game settings.",
		event_name: "bbblackjack",
		highlight: true,
	},
	{
		name: "UDLE by ZYUDS Cadila",
		type: "Game",
		tags: [
			"Unity3D", "C#"
		],
		links: [
			{
				Name: "Android",
				url: "https://play.google.com/store/apps/details?id=com.zyduscadila.udle"
			}
		],
		img: "projects/udle_thumb.png",
		detail: "Learning based Board game for Zydus Cadila internal employees to learn about new products more fun way.",
		event_name: "udle",
		highlight: true,
	},
	{
		name: "Scripts Manager for Unity3D",
		type: "tool",
		tags: [
			"Unity3D", "C#", "EditorScript"
		],
		links: [
			{
				Name: "github",
				url: "https://github.com/prashant-singh/scripts-manager-unity3d"
			}
		],
		img: "projects/scriptsManagerGif.gif",
		detail: "Gives the list of scripts in the current scene with gameobject reference.",
		event_name: "scripts_manager",
		highlight: true,
	},
	{
		name: "Speed Match for Lumosity",
		type: "Game",
		tags: [
			"Unity3D", "C#"
		],
		links: [
			{
				Name: "website",
				url: "https://www.lumosity.com/en/brain-games/speed-match/"
			}
		],
		detail: "A fast-paced exercise, Speed Match exercises your brain’s ability to process information and thus improves a wide variety of cognitive skills.",
		event_name: "speed_match",
		highlight: false,
	},
	{
		name: "Lost In Migration for Lumosity",
		type: "Game",
		tags: [
			"Unity3D", "C#"
		],
		links: [
			{
				Name: "website",
				url: "https://www.lumosity.com/en/brain-games/lost-in-migration/"
			}
		],
		detail: "Lost in Migration exercises your attention abilities. Games like Lost in Migration can boost your focus, helping you avoid distraction and improve productivity at work.",
		event_name: "lost_in_migration",
		highlight: false,
	}
];

export default workList;