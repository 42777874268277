const eventNames = {
	project_page_view: "project_page_view",
	download_resume_clicked: "download_resume_clicked",
	github_author_clicked: "github_author_clicked",
	github_footer_clicked: "github_footer_clicked",
	linkedin_author_clicked: "linkedin_author_clicked",
	linkedin_footer_clicked: "linkedin_footer_clicked",
	mail_author_clicked: "mail_author_clicked",
	mail_footer_clicked: "mail_footer_clicked",
	project_event: "_project_clicked",
};
export default eventNames;