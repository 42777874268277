import 'bootstrap/dist/css/bootstrap.min.css';
import profile from '../data/profile';
import '../css/default.css';
import { useEffect } from 'react';

const DownloadApp = () => {
    useEffect(() => {
        document.title = profile.name + ' | Download';
    }, []);

    return (
        <div className="container">
            <a href="itms-services://?action=download-manifest&url=https://thisisprashant.com/avreels.plist" id="text"> Download our application</a>
        </div >
    );
}

export default DownloadApp;